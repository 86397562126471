export const realtorData = {
  email: 'VERONICACONCHA@UNNE.CL',
  phone:'56989760307'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'VERONICACONCHA@UNNE.CL',
  phone:'989760307',
  wsp:'989760307',
}
